import React, { useEffect, useState } from "react";
import TableList from "../../components/TableList/TableList";
import Pagination, {
  defaultPaginationConfig,
} from "../../components/reusable/Pagination/Pagination";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError } from "../../utils/toast";
import "./Eventos.css";
import Button from "../../components/reusable/Button/Button";
import { useAppDispatch } from "../../app/store";
import { setLoadingPageScreen } from "../../features/Dashboard/Slices/dashboardSlice";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";

function Eventos() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEventos] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [eventos, setEventos] = useState<any[]>([]);
  const pageSize = 100;
  const dispatch = useAppDispatch();

  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };

  const delEventos = async () => {
    await dashboardAPI.delEventos();
    setLoading(true);
    try {
      const page = 0;
      const newData = await dashboardAPI.getEventosPaginated(pageSize, page);
      setCurrentPage(1);
      setEventos(newData.eventos);
      setTotalEventos(newData.totalCount);
    } catch (error) {
      toastError();
    }
    setShowDeleteConfirmation(false);
    setLoading(false);
  };

  const getEventos = async (newPage?: number) => {
    setLoading(true);
    try {
      const page = newPage ? (newPage - 1) * pageSize : 0;
      console.log("newPage;", newPage);
      console.log("page:", page);
      const newData = await dashboardAPI.getEventosPaginated(pageSize, page);
      if (newPage) {
        setCurrentPage(newPage);
      } else {
        setCurrentPage(1);
      }
      setEventos(newData.eventos);
      setTotalEventos(newData.totalCount);
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  useEffect(() => {
    getEventos();
  }, []);

  return (
    <div className="flex flex-1">
      <ModalConfirmation
        topBorderWarn
        show={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => delEventos()}
        title="¿Desea eliminar todos los eventos registrados?"
      />
      <div className="eventosPage">
        <div className="pt-3 flex flex-row items-center">
          <strong className="pl-10 text-lg">Eventos</strong>
          <Button
            className="ml-3"
            label="Actualizar lista"
            onClick={() => getEventos()}
          />
          <Button
            className="ml-3"
            label="Borrar todo"
            onClick={() => setShowDeleteConfirmation(true)}
            theme="red"
          />
        </div>
        <div className="flex flex-col px-10 py-2">
          {eventos.length < 1 && <h2>No se encontraron eventos</h2>}
          <div className="tableListCont">
            <TableList list={eventos} />
          </div>
          <Pagination
            onPageChange={getEventos}
            currentPage={currentPage}
            totalCount={totalEvents}
          />
        </div>
      </div>
    </div>
  );
}

export default Eventos;
