import React from "react";
import "./Switch.css";
import SlSwitch from "@shoelace-style/shoelace/dist/react/switch";
interface Props {
  checked?: boolean;
  label?: string;
  onChange?: () => void;
  className?: string;
}

function Switch(props: Props) {
  const { checked = false, label = "", className = "", onChange = () => {} } = props;
  return (
    <SlSwitch onSlChange={onChange} className={className} checked={checked}>
      {label}
    </SlSwitch>
  );
}

export default Switch;
