import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { conf } from "./utils/config";
import { Provider } from "react-redux";
import store from "./app/store";
import { GoogleOAuthProvider } from "@react-oauth/google";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const defaultConfig: AxiosRequestConfig = {
  baseURL: conf("BACKEND_API_URL"),
};

const GOOGLE_ID = process.env.GOOGLE_APP_CLIENT_ID;


axios.interceptors.request.use(
  (config) => {
    config.baseURL = defaultConfig.baseURL;
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      } as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="482262249936-7e6eejmgq433t225il5mbd3352ofqvbo.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
