import { Navigate, Outlet } from "react-router-dom";

interface Props {
  isAuthenticated: boolean;
}

const ProtectedRoute = ({ isAuthenticated }: Props) => {
  const tkn = localStorage.getItem("token");

  return isAuthenticated || tkn ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
