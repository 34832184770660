import React, { useEffect, useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import Button from "../../components/reusable/Button/Button";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError, toastInfo, toastSuccess } from "../../utils/toast";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import SlOption from "@shoelace-style/shoelace/dist/react/option";
import SlSelect from "@shoelace-style/shoelace/dist/react/select";
import Divider from "../../components/reusable/Divider/Divider";
import { useSelector } from "react-redux";
import {
  selectEstadoDelSistema,
  setLoadingPageScreen,
} from "../../features/Dashboard/Slices/dashboardSlice";
import { getParametros } from "../../features/Dashboard/AsyncThunks/parametros/getParametros";
import { EstadoSistema, Parametros } from "../../interfaces/parametros";
import { useAppDispatch } from "../../app/store";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";

function Home() {
  const paramEstadoSistema = useSelector(selectEstadoDelSistema);
  const [estadoSistema, setEstadoSistema] = useState<undefined | string>();
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const dispatch = useAppDispatch();

  const setLoading = (loaderState: boolean) => {
    dispatch(setLoadingPageScreen(loaderState));
  };

  const handleUpdateSystem = async () => {
    setLoading(true);
    try {
      if (estadoSistema) {
        await dashboardAPI.updateParametro(
          Parametros.EstadoSistema,
          estadoSistema
        );
        toastSuccess("Parámetro actualizado correctamente");
        await handleLogoutGeneral();
      } else {
        toastInfo("No se encuentra estado sistema seleccionado");
      }
    } catch (error: any) {
      toastError(error?.message);
    }
    setShowModalConfirmation(false);
    setLoading(false);
  };

  const getParams = async () => {
    setLoading(true);
    await dispatch(getParametros());
    setLoading(false);
  };

  const handleLogoutGeneral = async () => {
    setLoading(true);
    try {
      await dashboardAPI.logoutGeneral();
      toastSuccess("Logout - Broadcast enviado correctamente");
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    if (paramEstadoSistema) {
      setEstadoSistema(paramEstadoSistema);
    }
  }, [paramEstadoSistema]);

  return (
    <div className="px-5 py-5">
      <ModalConfirmation
        show={showModalConfirmation}
        onClose={() => setShowModalConfirmation(false)}
        onConfirm={handleUpdateSystem}
        title="¿Desea actualizar el estado del sistema?"
        message=""
      />
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <strong className="px-3">Estado del sistema</strong>
          <SlSelect
            value={estadoSistema}
            className="w-44 mx-3"
            onSlChange={(e: any) => setEstadoSistema(e?.target?.value)}
          >
            <SlOption value={EstadoSistema.ABIERTO}>Abierto</SlOption>
            <SlOption value={EstadoSistema.BY_PASS}>By pass</SlOption>
            <SlOption value={EstadoSistema.CERRADO}>Cerrado</SlOption>
            <SlOption value={EstadoSistema.MANTENIMIENTO}>
              Mantenimiento
            </SlOption>
          </SlSelect>
          <Button
            className="w-80"
            label="Actualizar estado del sistema"
            onClick={() => setShowModalConfirmation(true)}
          />
        </div>
        <Divider className="bg-black my-3" />
        <Button
          className="w-32"
          label="Logout general"
          onClick={handleLogoutGeneral}
        />
      </div>
    </div>
  );
}

export default Home;
