import "./Searchbar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSidebarOpen,
  toggleSidebar,
} from "../../features/Dashboard/Slices/dashboardSlice";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import {
  selectUser,
  setAuthtenticated,
  setToken,
} from "../../features/Dashboard/Slices/userSlice";
import { useNavigate } from "react-router-dom";
import SlBadge from "@shoelace-style/shoelace/dist/react/badge";

function Searchbar() {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(selectSidebarOpen);
  const { email, rol } = useSelector(selectUser);
  const navigate = useNavigate();

  const toggleLeftBar = async () => {
    await dispatch(toggleSidebar());
  };

  const handleCerrarSesion = () => {
    localStorage.clear();
    dispatch(setToken(null));
    dispatch(setAuthtenticated(false));
    navigate("/login");
  };

  return (
    <div className={`searchBar ${!sidebarOpen ? "searchbar-full-width" : ""}`}>
      <button onClick={toggleLeftBar}>
        <SlIcon
          name={`${sidebarOpen ? "text-indent-right" : "text-indent-left"}`}
          className="text-primary toggleIcon"
        />
      </button>
      <div className="header-user-info">
        {email && rol && (
          <p>
            <span>{rol}</span>
            {email}
          </p>
        )}
        <SlButton
          size="small"
          type="button"
          variant="danger"
          outline
          onClick={handleCerrarSesion}
          title="Cerrar sesión"
        >
          <SlIcon slot="suffix" name="door-closed"></SlIcon>
        </SlButton>
      </div>
    </div>
  );
}

export default Searchbar;
