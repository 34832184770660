import React from "react";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
interface Props {
  label: string;
}

function AlertInfo({ label = "" }: Props) {
  return (
    <SlAlert open>
      <SlIcon slot="icon" name="info-circle" />
      {label}
    </SlAlert>
  );
}

export default AlertInfo;
