import React, { useState } from "react";
import AlumnosRegistrados from "./secciones/AlumnosRegistrados";
import DetalleAlumno from "./secciones/DetalleAlumno";
import AlumnosInicializados from "./secciones/AlumnosInicializados";
import "./Alumnos.css";
import Divider from "../../components/reusable/Divider/Divider";

function Alumnos() {
  const [currentSeccion, setCurrentSeccion] = useState("listado");
  const listadoSelected = currentSeccion === "listado";
  const detalleSelected = currentSeccion === "detalle";
  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-row justify-around">
        <div
          className={`alumnobtn ${listadoSelected ? "selected" : ""}`}
          onClick={() => setCurrentSeccion("listado")}
        >
          <strong>Listado</strong>
        </div>
        <div
          className={`alumnobtn ${detalleSelected ? "selected" : ""}`}
          onClick={() => setCurrentSeccion("detalle")}
        >
          <strong>Detalle</strong>
        </div>
      </div>
      {listadoSelected && (
        <div className="px-10">
          <AlumnosRegistrados
            onDetailPressed={() => setCurrentSeccion("detalle")}
          />
          <Divider />
          <AlumnosInicializados />
        </div>
      )}
      {detalleSelected && <DetalleAlumno />}
    </div>
  );
}

export default Alumnos;
